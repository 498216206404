<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template functional>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <p>
      <span>Need help? Join us on </span>
      <a
        href="https://join.slack.com/t/hoverlayworkspace/shared_invite/zt-tgbw5a4w-mQwMyNyihSZcGDKz_rfxfw"
        target="_blank"
        rel="nofollow"
        >Slack
      </a>
      <span>&nbsp </span>

      <!-- © 2020 Hoverlay All rights reserved – Patent Pending -->
      <span>&copy; </span>
      <span>{{ new Date().getFullYear() }} </span>
      <a href="http://hoverlay.com/" target="_blank" rel="nofollow">Hoverlay </a>
      <span class="hidden sm:inline-block"> All rights reserved – Patent Pending</span>
    </p>
    <span class="md:flex hidden items-center">
      <span>Made with</span>
      <feather-icon icon="HeartIcon" svgClasses="stroke-current text-danger w-6 h-6" class="ml-2" />
      <span>&nbsp in the USA and France</span>
    </span>
  </footer>
</template>

<script>
export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String,
    },
  },
}
</script>
